export enum LoginFaceValidationResult {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
    WAITING = 'WAITING',
}

export enum LoginDirection {
    IN = 'IN',
    OUT = 'OUT',
    NOT_SET = 'NOT_SET',
}

export enum LoginPositionValidationResult {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
    WAITING = 'WAITING',
}

export enum FaceValidationErrorReason {
    NO_REFERENCE_IMAGE = 'NO_REFERENCE_IMAGE',
    NO_LOGIN_IMAGE = 'NO_LOGIN_IMAGE',
    REQUEST_ERROR = 'REQUEST_ERROR',
    NO_FACE_FOUND = 'NO_FACE_FOUND',
    UNKNOWN_ERROR = 'UNKNOWN',
    RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
}
