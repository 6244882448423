import { IntlShape } from 'react-intl';
import { array, boolean, number, object, ref, string } from 'yup';
import { Role } from '../types';

/**
 * Class that provides schema for different formik forms
 * Each schema represents one step in the form
 */
class SchemaProvider {
    public expressions = {
        name: /^[a-zA-Z0-9á-žÁ-Ž]+$/,
        userName: /^[a-zA-Z0-9\-_]+$/,
        companyName: /^[a-zA-Z0-9á-žÁ-Ž\-_.+= ]+$/,
    };

    public getLoginSchema(intl: IntlShape) {
        const messages = this.messages(intl);
        return object().shape({
            password: string().required(messages.passwordEmpty),
            userName: string().required(messages.valueEmpty),
        });
    }

    public getPasswordResetFormSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return object().shape({
            password: string().min(6, messages.passwordLength).max(50, messages.passwordLength).required(messages.passwordEmpty),
            confirmPassword: string()
                .required(messages.confirmPasswordEmpty)
                .oneOf([ref('password')], messages.confirmPasswordMismatch),
        });
    }

    public getRegisterSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return {
            userData: object().shape({
                firstName: string()
                    .min(3, messages.nameShort)
                    .max(50, messages.nameShort)
                    .required(messages.nameEmpty)
                    .matches(this.expressions.name, messages.invalidCharacters),
                surname: string()
                    .min(3, messages.nameShort)
                    .max(50, messages.nameShort)
                    .required(messages.nameEmpty)
                    .matches(this.expressions.name, messages.invalidCharacters),
                email: string().email(messages.emailEmpty).required(messages.emailEmpty),
                password: string().min(6, messages.passwordLength).max(50, messages.passwordLength).required(messages.passwordEmpty),
                confirmPassword: string()
                    .required(messages.confirmPasswordEmpty)
                    .oneOf([ref('password')], messages.confirmPasswordMismatch),
                gdprAccepted: boolean().required().isTrue(messages.acceptChoice),
                termsOfUseAccepted: boolean().required().isTrue(messages.acceptChoice),
            }),
            companySetup: object().shape({
                companyMode: string(),

                companyName: string().when(['companyMode'], ([companyMode]) => {
                    return companyMode === 'CREATE'
                        ? string()
                              .min(2, messages.nameShort)
                              .max(50, messages.nameShort)
                              .required(messages.nameEmpty)
                              .matches(this.expressions.companyName, messages.invalidCharacters)
                        : string();
                }),
                inviteCode: string().when(['companyMode'], ([companyMode]) => {
                    return companyMode === 'JOIN' ? string().required(messages.valueEmpty) : string();
                }),
            }),
        };
    }

    public getSignatureModalSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return object({
            approvedBy: string().required(messages.valueEmpty),
            signature: string().required(messages.valueEmpty),
        });
    }

    public getVehicleOperationSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return {
            driverDateVehicle: object({
                date: string().required(messages.valueEmpty),
                driver: string().required(messages.valueEmpty),
                vehicleId: string().required(messages.valueEmpty),
                constructionSite: string().required(messages.valueEmpty),
            }),
            customer: object({
                customerName: string().min(3, messages.nameShort).required(messages.nameEmpty),
                customerOrganizationIdentifier: string().optional().nullable(),
                customerVatIdentifier: string().optional().nullable(),
                customerTaxIdentifier: string().optional().nullable(),
                customerCity: string().optional().nullable(),
                customerStreet: string().optional().nullable(),
                customerPostalCode: string().optional().nullable(),
            }),
            carrier: object({
                carrierName: string().min(3, messages.nameShort).required(messages.nameEmpty),
                customerOrganizationIdentifier: string().optional().nullable(),
                customerVatIdentifier: string().optional().nullable(),
                customerTaxIdentifier: string().optional().nullable(),
                customerCity: string().optional().nullable(),
                customerStreet: string().optional().nullable(),
                customerPostalCode: string().optional().nullable(),
            }),
            drive: object({
                driveLoadPlace: string().optional().nullable(),
                driveLoadTime: string().when(['driveLoadPlace'], ([driveLoadPace]) => {
                    return driveLoadPace && string().required(messages.valueEmpty);
                }),
                driveLoadType: string().required(messages.valueEmpty),
                driveUnloadPlace: string().optional().nullable(),
                driveUnloadTime: string().when(['driveUnloadPlace'], ([driveUnloadPlace]) => {
                    return driveUnloadPlace && string().required(messages.valueEmpty);
                }),
            }),
            operationInfo: object({
                post: string().required(messages.valueEmpty),
                postDeparture: string().optional().nullable(),
                postArrival: string().optional().nullable(),
                initialKm: string().when(['postDeparture'], ([postDeparture]) => {
                    return postDeparture && string().required(messages.valueEmpty);
                }),
                finalKm: string().when(['postArrival'], ([postArrival]) => {
                    return postArrival && string().required(messages.valueEmpty);
                }),
            }),
        };
    }
    public getBusinessIdentitySchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return {
            contact: object({
                name: string().min(3, messages.nameShort).required(messages.nameEmpty),
                phone: string().optional().nullable(),
                email: string().email(messages.emailInvalid).optional().nullable(),
            }),
            businessIdentification: object({
                organizationIdentifier: string().optional().nullable(),
                vatId: string().optional().nullable(),
                taxId: string().optional().nullable(),
            }),
            address: object({
                street: string().optional().nullable(),
                city: string().optional().nullable(),
                postalCode: string().optional().nullable(),
                countryCode: string().optional().nullable(),
            }),
        };
    }

    public getManualAttendanceLogSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return {
            newRecord: object({
                userId: number().required(messages.valueEmpty),
                createdAt: string().required(messages.valueEmpty),
                direction: string().required(messages.valueEmpty),
                zoneId: number().nullable().optional(),
                note: string().optional(),
            }),
            updateRecord: object({
                createdAt: string().optional(),
                direction: string().optional(),
                zoneId: number().nullable().optional(),
                updateNote: string().optional(),
            }),
        };
    }

    public getZoneSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return object({
            name: string().min(3, messages.nameShort).required(messages.nameEmpty),
            polygon: array().min(3, messages.zone.minPoints).required(messages.zone.minPoints),
        });
    }

    public getEmployeeUpdateSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return object({
            firstName: string().min(3, messages.nameShort).required(messages.nameEmpty),
            surname: string().min(3, messages.nameShort).optional(),
            password: string().min(6, messages.nameShort).optional(),
            confirmPassword: string().when(['password'], ([password]) => {
                return (
                    password &&
                    string()
                        .required(messages.confirmPasswordEmpty)
                        .oneOf([ref('password')], messages.confirmPasswordMismatch)
                );
            }),
            role: string().required(messages.valueEmpty),
            mode: string().when(['role'], ([role]) => {
                return role === Role.TEAM_ACCOUNT ? string().required(messages.valueEmpty) : string();
            }),
            defaultZoneId: number().nullable().optional(),
        });
    }

    public getNewEmployeeSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return object({
            userName: string()
                .min(3, messages.nameShort)
                .required(messages.nameEmpty)
                .matches(this.expressions.userName, messages.invalidCharacters),
            firstName: string()
                .min(3, messages.nameShort)
                .required(messages.nameEmpty)
                .matches(this.expressions.name, messages.invalidCharacters),
            surname: string().when(['role'], ([role]) => {
                return role === Role.TEAM_ACCOUNT
                    ? string().min(3, messages.nameShort)
                    : string()
                          .min(3, messages.nameShort)
                          .required(messages.nameEmpty)
                          .matches(this.expressions.name, messages.invalidCharacters);
            }),
            password: string().min(6, messages.passwordLength).required(messages.passwordEmpty),
            confirmPassword: string()
                .required(messages.confirmPasswordEmpty)
                .oneOf([ref('password')], messages.confirmPasswordMismatch),
            role: string().required(messages.valueEmpty),
            mode: string().when(['role'], ([role]) => {
                return role === Role.TEAM_ACCOUNT ? string().required(messages.valueEmpty) : string();
            }),
            defaultZoneId: number().nullable().optional(),
        });
    }

    public getVehicleSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return {
            main: object({
                name: string().min(3, messages.nameShort).required(messages.nameEmpty),
                type: string().required(messages.valueEmpty),
            }),
            info: object({
                model: string().optional().nullable(),
                brand: string().required(messages.valueEmpty).nullable(),
                plate: string().optional().nullable(),
            }),
        };
    }

    public contactUsSchema(intl: IntlShape) {
        const messages = this.messages(intl);

        return object({
            name: string().required(messages.nameEmpty),
            contact: string().required(messages.valueEmpty),
            message: string().required(messages.valueEmpty),
        });
    }

    private messages(intl: IntlShape) {
        return {
            emailEmpty: intl.formatMessage({ id: 'validation.emailEmpty' }),
            emailInvalid: intl.formatMessage({ id: 'validation.emailInvalid' }),
            nameEmpty: intl.formatMessage({ id: 'validation.NameEmpty' }),
            nameShort: intl.formatMessage({ id: 'validation.nameLength' }),
            valueEmpty: intl.formatMessage({ id: 'validation.common.valueEmpty' }),
            passwordEmpty: intl.formatMessage({ id: 'validator.passwordEmpty' }),
            passwordLength: intl.formatMessage({ id: 'validator.passwordLength' }),
            confirmPasswordEmpty: intl.formatMessage({ id: 'validation.confirmPasswordEmpty' }),
            confirmPasswordMismatch: intl.formatMessage({ id: 'validation.confirmPasswordMismatch' }),
            acceptChoice: intl.formatMessage({ id: 'validation.mustAcceptChoice' }),
            invalidCharacters: intl.formatMessage({ id: 'validation.invalidCharacters' }),
            zone: {
                minPoints: intl.formatMessage({ id: 'validation.zone.minPoints' }),
            },
        };
    }
}

export default new SchemaProvider();
