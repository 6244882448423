import * as React from 'react';
import { ChakraProvider, extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import router from './routing/routes';
import { IntlProvider } from 'react-intl';
import { getLanguageMessages, isLanguageAvailable } from './i18n/languages';
import { useSettingsStore } from './store/ui/SettingsStore';
import useAOS from './hooks/useAos';

export const App = () => {
    const customLocale = useSettingsStore((state) => state.language);

    const customTheme = extendTheme(
        {
            colors: {
                primary: {
                    '50': '#F0EEF7',
                    '100': '#D5CFE8',
                    '200': '#BBB0D9',
                    '300': '#A091C9',
                    '400': '#8572BA',
                    '500': '#6B54AB',
                    '600': '#554389',
                    '700': '#403267',
                    '800': '#2B2145',
                    '900': '#151122',
                },
                secondary: {
                    '50': '#FFF9E5',
                    '100': '#FFEEB8',
                    '200': '#FFE38A',
                    '300': '#FFD95C',
                    '400': '#FFCE2E',
                    '500': '#FFC300',
                    '600': '#CC9C00',
                    '700': '#997500',
                    '800': '#664E00',
                    '900': '#332700',
                },
                errorElevation: {
                    '50': '#39252B',
                    '100': '#4A1F23',
                    '200': '#5B191B',
                    '300': '#6C1313',
                    '400': '#7D0D0B',
                },
                warningElevationColors: {
                    '50': '#FFF3CD',
                    '100': '#FFECB3',
                    '200': '#FFE082',
                    '300': '#FFD54F',
                    '400': '#FFCA28',
                },
            },
        },
        withDefaultColorScheme({
            colorScheme: 'primary',
        })
    );

    const queryClient = new QueryClient();

    const getUsedLocale = () => {
        if (customLocale) {
            return customLocale;
        }

        const browserLanguage = navigator.language?.slice(0, 2)?.toLowerCase();

        if (isLanguageAvailable(browserLanguage)) {
            return browserLanguage;
        }

        return 'sk';
    };

    return (
        useAOS(),
        (
            <IntlProvider defaultLocale="sk" locale={getUsedLocale()} messages={getLanguageMessages(getUsedLocale())}>
                <ChakraProvider theme={customTheme}>
                    <QueryClientProvider client={queryClient}>
                        <RouterProvider router={router} />
                    </QueryClientProvider>
                </ChakraProvider>
            </IntlProvider>
        )
    );
};
