import { useEffect, useState } from 'react';
import { AttendanceLoginHistory, Zone } from '../../../types';
import CompanyService from '../../../query/services/CompanyService';
import { Box, Card, Icon, Stack, Text } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { ArrowDownIcon } from '@chakra-ui/icons';

interface AttendanceHistoryItemProps {
    item: AttendanceLoginHistory;
}

enum ExpectedKeys {
    createdAt = 'createdAt',
    note = 'note',
    zoneId = 'zoneId',
    direction = 'direction',
    approvedBy = 'approvedBy',
}

type ValueType = number | string | null | undefined;

const DiffItem: React.FC<{
    fieldKey: ExpectedKeys;
    oldValue: ValueType;
    newValue: ValueType;
}> = ({ fieldKey, oldValue, newValue }) => {
    const intl = useIntl();
    
    const [newZone, setNewZone] = useState<Zone | null>(null);
    const [oldZone, setOldZone] = useState<Zone | null>(null);

    useEffect(() => {
        const getZones = async () => {
            if (fieldKey === ExpectedKeys.zoneId) {
                const oldZoneResult = oldValue ? await CompanyService.getZoneById(oldValue as number) : null;
                setOldZone(oldZoneResult);
                const newZoneResult = newValue? await CompanyService.getZoneById(newValue as number) : null;
                setNewZone(newZoneResult);
            }
        };

        getZones();
    }, [oldValue, newValue, fieldKey]);

    const parseValue = (value: number | string | null | undefined, mode: 'new' | 'old'): React.ReactNode => {
        if (!value) {
            return <Text> -</Text>;
        }

        switch (fieldKey) {
            case ExpectedKeys.createdAt:
                return <Text>{`${intl.formatDate(value)} ${intl.formatTime(value)}`}</Text>;
            case ExpectedKeys.zoneId:
                return <Text> {(mode === 'new' ? newZone?.name : oldZone?.name) ?? '...'}</Text>;
            case ExpectedKeys.direction:
                return <Text>{intl.formatMessage({ id: value as string })}</Text>;
            case ExpectedKeys.note:
                return <Text>{value}</Text>;
            case ExpectedKeys.approvedBy:
                return null;
            default:
                return <Text>{value}</Text>;
        }
    };

    if (fieldKey === ExpectedKeys.approvedBy) {
        return null;
    }

    return (
        <Box>
            <Box position={'relative'} p={1} borderRadius={'md'} my={1} border={'1px'} borderColor={'red'}>
                {parseValue(oldValue, 'old')}
                <Icon
                    boxSize={5}
                    as={ArrowDownIcon}
                    backgroundColor={'primary.500'}
                    borderRadius={'full'}
                    color={'white'}
                    position="absolute"
                    right={'2'}
                    bottom="-6"
                    transform="translateY(-50%)"
                    zIndex="overlay"
                />
            </Box>
            <Box p={1} borderRadius={'md'} my={1} border={'1px'} borderColor={'green'}>
                {parseValue(newValue, 'new')}
            </Box>
        </Box>
    );
};

export const AttendanceHistoryItem: React.FC<AttendanceHistoryItemProps> = ({ item }) => {
    const intl = useIntl();

    const dataKeys = [...new Set([...Object.keys(item.oldData ?? {}), ...Object.keys(item.newData ?? {})])];

    const getKeyLabel = (key: string) => {
        switch (key as ExpectedKeys) {
            case ExpectedKeys.direction:
                return intl.formatMessage({ id: 'type' });
            case ExpectedKeys.note:
                return intl.formatMessage({ id: 'note' });
            case ExpectedKeys.zoneId:
                return intl.formatMessage({ id: 'zone' });
            case ExpectedKeys.createdAt:
                return intl.formatMessage({ id: 'field.date' });
            case ExpectedKeys.approvedBy:
                return intl.formatMessage({ id: 'field.approvedBy' });
            default:
                return key;
        }
    };

    return (
        <Card mb={4}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    padding: 10,
                }}
            >
                <Box>
                    <Text fontWeight={'500'}>{`${item.user.firstName} ${item.user.surname}`}</Text>
                </Box>
                <Stack style={{ flexDirection: 'row' }}>
                    <Text>{`${intl.formatDate(item.createdAt)} `}</Text>
                    <Text>{`${intl.formatTime(item.createdAt)}`}</Text>
                </Stack>
            </Box>
            {dataKeys.map((key) => (
                <Box p={3} key={key}>
                    <Text fontWeight={'500'}>{getKeyLabel(key)}</Text>
                    <DiffItem
                        oldValue={item.oldData?.[key as ExpectedKeys]}
                        // @ts-ignore
                        newValue={item.newData?.[key as ExpectedKeys]}
                        fieldKey={key as ExpectedKeys}
                    />
                </Box>
            ))}
        </Card>
    );
};
