import { FaceValidationErrorReason, LoginFaceValidationResult } from "../enums/LoginEnums";

export const faceValidationErrorReasonMap: Record<FaceValidationErrorReason, string> = {
    [FaceValidationErrorReason.NO_FACE_FOUND]: 'faceValidation.error.description.NO_FACE_FOUND', // No face found in the image
    [FaceValidationErrorReason.NO_LOGIN_IMAGE]: 'faceValidation.error.description.NO_LOGIN_IMAGE', // Problem with the image sent to the server
    [FaceValidationErrorReason.NO_REFERENCE_IMAGE]: 'faceValidation.error.description.NO_REFERENCE_IMAGE', // User has no reference image (profile picture)
    [FaceValidationErrorReason.UNKNOWN_ERROR]: 'faceValidation.error.description.UNKNOWN_ERROR', // Unknown error
    [FaceValidationErrorReason.REQUEST_ERROR]: 'faceValidation.error.description.REQUEST_ERROR', // Request to AWS failed
    [FaceValidationErrorReason.RESOURCE_NOT_FOUND]: 'faceValidation.error.description.RESOURCE_NOT_FOUND', // Error from server
  };
  
  export const faceValidationResultMap: Record<LoginFaceValidationResult, string> = {
    [LoginFaceValidationResult.SUCCESS]: 'faceValidation.result.description.SUCCESS', // Face validation was successful
    [LoginFaceValidationResult.ERROR]: 'faceValidation.result.description.ERROR', // Face validation failed
    [LoginFaceValidationResult.FAILED]: 'faceValidation.result.description.FAILED', // Face validation failed
    [LoginFaceValidationResult.WAITING]: 'faceValidation.result.description.WAITING', // User has no reference image (profile picture)
  };
  