import {
    Box,
    Heading,
    Wrap,
    WrapItem,
    Flex,
    HStack,
    Image,
    Icon,
    Text,
    Tooltip,
    useTheme,
    useBreakpointValue,
    useColorModeValue,
    Button,
    ButtonGroup,
    useDisclosure,
    useToast,
    Skeleton,
} from '@chakra-ui/react';
import { FaMap, FaClock, FaPencilAlt } from 'react-icons/fa';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import { createImageUrl } from '../../query/apiClient';
import { AttendanceLogin, AttendanceLoginDetail, User } from '../../types';
import { PositionValidationIcon, FaceRecognitionIcon, ManualAttendanceIcon, OfflineAttendanceIcon } from '../lists/AttendanceTableItem';
import MapPreview from '../maps/MapPreview';
import { useIntl } from 'react-intl';
import { AttendancePreviewSkeleton } from './AttendancePreviewSkeleton';
import { CheckIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import UserPolicy from '../../policies/UserPolicy';
import { useDeleteAttendance, useUpdateAttendance } from '../../query/resource-hooks/company';
import { AxiosError } from 'axios';
import { UpdateAttendanceModal } from './UpdateAttendanceModal';
import { DeleteAlertDialog } from '../ui/DeleteAlertDialog';
import ImagePreviewModal from '../common/ImagePreviewModal';
import { Link } from 'react-router-dom';
import { faceValidationErrorReasonMap, faceValidationResultMap } from '../../types/valueMaps/errorDescriptions';

type AttendancePreviewProps = {
    attendance?: AttendanceLoginDetail;
    onUpdateSuccess?: (attendance: AttendanceLogin) => void;
    onDeleteSuccess?: () => void;
    onUpdateError?: (error: AxiosError) => void;
    onDeleteError?: (error: AxiosError) => void;
    isLoading: boolean;
    loggedUser?: User;
};

export const AttendancePreview: React.FC<AttendancePreviewProps> = ({
    attendance,
    isLoading,
    loggedUser,
    onDeleteError,
    onDeleteSuccess,
    onUpdateError,
    onUpdateSuccess,
}) => {
    const intl = useIntl();
    const theme = useTheme();

    const toast = useToast();
    const deleteAttendance = useDeleteAttendance(handleDeleteSuccess, handleDeleteError);
    const updateAttendance = useUpdateAttendance(handleApproveSuccess, handleUpdateError);
    const mapHeight = useBreakpointValue({ base: 200, md: 250, xl: 300 });
    const wrapItemColor = useColorModeValue('gray.300', 'gray.600');

    const updateRecordDisclosure = useDisclosure();
    const deleteAlertDisclosure = useDisclosure();
    const imagePreviewDisclosure = useDisclosure();

    function handleDeleteSuccess() {
        toast({
            title: intl.formatMessage({ id: 'data.deleted' }),
            status: 'success',
            duration: 10000,
            isClosable: true,
            position: 'top',
        });
        deleteAlertDisclosure.onClose();
        onDeleteSuccess?.();
    }

    function handleDeleteError(error: AxiosError) {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            status: 'error',
            duration: 10000,
            isClosable: true,
            position: 'top',
        });
        deleteAlertDisclosure.onClose();
        onDeleteError?.(error);
    }

    async function handleApproveAttendance() {
        await updateAttendance.mutate({ id: attendance?.id!, data: { approve: true } });
    }

    function handleApproveSuccess(login: AttendanceLogin) {
        toast({
            title: intl.formatMessage({ id: 'attendance.approve.success' }),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onUpdateSuccess?.(login);
    }

    function handleUpdateError(error: AxiosError) {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onUpdateError?.(error);
    }

    function handleDeleteAttendance() {
        deleteAttendance.mutate(attendance?.id!);
        deleteAlertDisclosure.onClose();
    }

    if (isLoading || !attendance) {
        return <AttendancePreviewSkeleton />;
    }

    return (
        <Box>
            <Box h={{ base: '200px', md: '250px', xl: '300px' }} position="relative">
                <MapPreview
                    coordinates={attendance?.manual === true ? undefined : attendance?.coordinates}
                    zone={attendance?.zone}
                    markerText={attendance?.user?.fullName}
                    height={mapHeight}
                    accuracy={attendance?.accuracy || undefined}
                />
                {attendance?.loginImageUrl && (
                    <Image
                        src={createImageUrl(attendance?.loginImageUrl)}
                        alt="Attendance Photo"
                        backgroundColor={'gray.200'}
                        borderWidth={10}
                        border={'solid'}
                        borderRadius={'lg'}
                        borderColor={'gray.500'}
                        zIndex={1000}
                        h={{ base: 150, md: 250, xl: 300 }}
                        w={{ base: 150, md: 250, xl: 300 }}
                        aspectRatio={1}
                        objectFit={'cover'}
                        transform={'translate(-50%, 0%)'}
                        position={'absolute'}
                        top={'70%'}
                        left={{ 'base': '50%', 'sm': '50%', 'md': '23%', 'lg': '18%', 'xl': '15%', '2xl': '14%' }}
                        onClick={imagePreviewDisclosure.onOpen}
                        cursor={'pointer'}
                        fallback={
                            <Skeleton
                                borderWidth={10}
                                border={'solid'}
                                borderRadius={'lg'}
                                borderColor={'gray.500'}
                                zIndex={1000}
                                h={{ base: 150, md: 250, xl: 300 }}
                                w={{ base: 150, md: 250, xl: 300 }}
                                aspectRatio={1}
                                objectFit={'cover'}
                                transform={'translate(-50%, 0%)'}
                                position={'absolute'}
                                top={'70%'}
                                left={{ 'base': '50%', 'sm': '50%', 'md': '23%', 'lg': '18%', 'xl': '15%', '2xl': '14%' }}
                            />
                        }
                    />
                )}
            </Box>
            <ImagePreviewModal
                imageUrl={createImageUrl(attendance?.loginImageUrl!)}
                isOpen={imagePreviewDisclosure.isOpen}
                onClose={imagePreviewDisclosure.onClose}
            />
            <Flex
                direction={{ base: 'column', md: 'column' }}
                ml={{ 'base': 0, 'md': '45%', 'lg': '35%', 'xl': '30%', '2xl': '28%' }}
                mt={{ base: '115px', md: 0 }}
            >
                <Box>
                    <Heading
                        _hover={{ textDecoration: 'underline' }}
                        textAlign={{ base: 'center', md: 'left' }}
                        as={Link}
                        to={`/employees/detail/${attendance.userId}`}
                    >
                        {attendance?.user?.fullName}
                    </Heading>
                </Box>
                <Wrap mt={2} spacing={2} justify={{ base: 'center', md: 'left' }}>
                    <WrapItem>
                        <Tooltip label={intl.formatMessage({ id: 'zone' })}>
                            <Flex
                                background={wrapItemColor}
                                p={1}
                                borderRadius={'md'}
                                justify={'center'}
                                align={'center'}
                                as={Link}
                                to={`/zones/detail/${attendance.zoneId}`}
                            >
                                <Icon mr={2} as={FaMap} />
                                <Text fontSize={'lg'} align={'center'}>
                                    {attendance?.zone?.name ?? intl.formatMessage({ id: 'attendance.noZone' })}
                                </Text>
                            </Flex>
                        </Tooltip>
                    </WrapItem>
                    <WrapItem>
                        <Flex background={wrapItemColor} p={1} borderRadius={'md'} justify={'center'} align={'center'}>
                            <Icon mr={2} as={FaClock} />
                            <Text pr={1} fontSize={'lg'} align={'center'}>
                                {`${intl.formatDate(attendance?.createdAt)}`}
                            </Text>
                            <Text fontWeight={'bold'}>{`${intl.formatTime(attendance?.createdAt)}`}</Text>
                        </Flex>
                    </WrapItem>
                    {attendance?.creator && attendance?.creator?.id !== attendance?.user?.id && (
                        <WrapItem>
                            <Tooltip label={intl.formatMessage({ id: 'createdBy' })}>
                                <Flex
                                    align={'center'}
                                    background={wrapItemColor}
                                    as={Link}
                                    to={`/employees/detail/${attendance.createdBy}`}
                                    p={1}
                                    borderRadius={'md'}
                                    justify={'center'}
                                >
                                    <Icon mr={2} as={FaPencilAlt} />
                                    <Text fontSize={'lg'} align={'center'}>
                                        {`${attendance?.creator?.fullName}`}
                                    </Text>
                                </Flex>
                            </Tooltip>
                        </WrapItem>
                    )}
                    {attendance?.approver && (
                        <WrapItem>
                            <Tooltip label={intl.formatMessage({ id: 'doc.sign.approvedBy' })}>
                                <Flex align={'center'} background={wrapItemColor} p={1} borderRadius={'md'} justify={'center'}>
                                    <Icon mr={2} as={CheckIcon} />
                                    <Text fontSize={'lg'} align={'center'}>
                                        {`${attendance?.approver?.fullName}`}
                                    </Text>
                                </Flex>
                            </Tooltip>
                        </WrapItem>
                    )}
                    <WrapItem>
                        <HStack p={1} borderRadius={'md'} justify={'center'} align={'center'} background={wrapItemColor}>
                            <Tooltip label={intl.formatMessage({ id: attendance?.direction })}>
                                <Box>
                                    <Icon
                                        as={attendance?.direction === 'IN' ? FiLogIn : FiLogOut}
                                        color={attendance?.direction === 'IN' && theme.colors.primary[500]}
                                        boxSize="6"
                                    />
                                </Box>
                            </Tooltip>
                            <PositionValidationIcon item={attendance!} />
                            <FaceRecognitionIcon item={attendance!} />
                            <ManualAttendanceIcon creatorName={attendance?.creator?.fullName} item={attendance!} />
                            <OfflineAttendanceIcon item={attendance!} />
                        </HStack>
                    </WrapItem>
                </Wrap>
                {attendance?.faceValidationErrorReason && (
                    <Box
                        marginY={2}
                        flexDirection="column"
                        borderWidth={2}
                        borderColor={'errorElevation.200'}
                        background="red"
                        p={2}
                        borderRadius="md"
                        maxWidth={'fit-content'}
                    >
                        <Text color={'white'}>{intl.formatMessage({ id: faceValidationResultMap[attendance.faceValidation] })}</Text>
                        <Text color={'white'}>
                            {intl.formatMessage({ id: faceValidationErrorReasonMap[attendance.faceValidationErrorReason] })}
                        </Text>
                    </Box>
                )}
                {loggedUser && (
                    <ButtonGroup mt={2} display={'flex'} justifyContent={{ base: 'center', md: 'left' }}>
                        <Button
                            isDisabled={!UserPolicy.canEditAttendance(loggedUser!, attendance)}
                            onClick={updateRecordDisclosure.onOpen}
                            leftIcon={<EditIcon />}
                            borderRadius={'full'}
                        >
                            {intl.formatMessage({ id: 'edit' })}
                        </Button>
                        <Button
                            isDisabled={!UserPolicy.canEditAttendance(loggedUser!, attendance)}
                            onClick={deleteAlertDisclosure.onOpen}
                            leftIcon={<DeleteIcon />}
                            borderRadius={'full'}
                            colorScheme="red"
                        >
                            {intl.formatMessage({ id: 'delete' })}
                        </Button>
                        {UserPolicy.canApproveAttendance(loggedUser!, attendance) && (
                            <Button
                                isDisabled={!UserPolicy.canEditAttendance(loggedUser!, attendance)}
                                onClick={() => handleApproveAttendance()}
                                leftIcon={<CheckIcon />}
                                borderRadius={'full'}
                                colorScheme="green"
                            >
                                {intl.formatMessage({ id: 'attendance.approve' })}
                            </Button>
                        )}
                    </ButtonGroup>
                )}
            </Flex>
            <UpdateAttendanceModal
                onSuccess={onUpdateSuccess}
                onError={onUpdateError}
                isOpen={updateRecordDisclosure.isOpen}
                onClose={updateRecordDisclosure.onClose}
                updatedRecord={attendance}
            />
            <DeleteAlertDialog
                isLoading={deleteAttendance.isLoading}
                isOpen={deleteAlertDisclosure.isOpen}
                onClose={deleteAlertDisclosure.onClose}
                onConfirm={handleDeleteAttendance}
            />
        </Box>
    );
};
